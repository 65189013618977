import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthProvider';

const Analytics = () => {
    const { user } = useAuth();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [records, setRecords] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (user && user.company) {
                try {
                    setLoading(true);
                    const response = await axios.get(`/analytics/${user.company}`);
                    setData(response.data.analytics);
                } catch (error) {
                    setError('Failed to fetch analytics data');
                } finally {
                    setLoading(false);
                }
            }
        };
        const fetchhistorical = async () => {
            if (user && user.company) {
                try {
                    setLoading(true);
                    const recs = await axios.get(`/tendayanalytics/${user.company}`);
                    setRecords(recs.data.analytics);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setError('Failed to fetch analytics data');
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
        fetchhistorical();
    }, [user]);



    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!data) return <div>No data available</div>;

    return (
        <>
            <div>

                <div className='text-center text-text-2xl font-bold sm:text-3xl md:text-3xl dark:text-white'>
                    Analytics  for : <span>{user?.company}</span>
                </div>

                <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">

                        <div class="flex flex-col bg-white border shadow-sm rounded-xl">
                            <div class="p-4 md:p-5">
                                <div class="flex items-center gap-x-2 justify-center">
                                    <p class="text-xs uppercase tracking-wide text-gray-700">
                                        Documents Processed Today
                                    </p>

                                </div>

                                <div class="mt-1 flex items-center gap-x-4 justify-center">
                                    <h3 class="text-xl sm:text-2xl font-medium text-gray-800">
                                        {data.uploaded || 0}
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col bg-white border shadow-sm rounded-xl">
                            <div class="p-4 md:p-5">
                                <div class="flex items-center gap-x-2 justify-center">
                                    <p class="text-xs uppercase tracking-wide text-gray-700">
                                        Manual Edits
                                    </p>
                                </div>

                                <div class="mt-1 flex items-center gap-x-2 justify-center">
                                    <h3 class="text-xl sm:text-2xl font-medium text-gray-800">
                                        {data.edited || 0}
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col bg-white border shadow-sm rounded-xl">
                            <div class="p-4 md:p-5">
                                <div class="flex items-center gap-x-2 justify-center">
                                    <p class="text-xs uppercase tracking-wide text-gray-700">
                                        Failed to Process
                                    </p>
                                </div>

                                <div class="mt-1 flex items-center gap-x-2 justify-center">
                                    <h3 class="text-xl sm:text-2xl font-medium text-gray-800">
                                        {data.failed_files || 0}
                                    </h3>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div>
                {records && records.length > 0 ? (
                    <>
                        <div class="px-6 py-4 border-b border-gray-200 dark:border-neutral-700">
                            <h2 class="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                                Historical Data
                            </h2>
                            <p class="text-sm text-gray-600 dark:text-neutral-400">
                                The table below shows historical data for the past 10 days.
                            </p>
                        </div>
                        <table className="min-w-full bg-white divide-y divide-gray-200 dark:divide-neutral-700">
                            <thead className="bg-gray-50 dark:bg-neutral-800">
                                <tr>
                                    {Object.keys(records[0]).map((key) => (
                                        <th key={key} className="py-2 px-2 border-b border-gray-200 bg-gray-100 text-sm leading-4 text-gray-600 uppercase tracking-wider text-start whitespace-nowrap">
                                            <span class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                                                {key}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className='divide-y divide-gray-200 dark:divide-neutral-700'>
                                {records.map((record, index) => (
                                    <tr key={index}>
                                        {Object.values(record).map((value, idx) => (
                                            <td key={idx} className="border-b border-gray-200 size-px whitespace-nowrap px-3 py-2">
                                                {value}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                ) : (
                    <div>No historical data</div>
                )}
                {/* <pre className="mt-4 p-4 bg-gray-100 rounded">
                {JSON.stringify(records, null, 2)}
            </pre> */}
            </div>
        </>
    );
};

export default Analytics;