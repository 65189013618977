import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import axios from 'axios';
import { useMemo } from 'react';

// Company-specific column configurations
const COMPANY_CONFIGS = {
    'aidocx demo': {
        columns: ['file_name', 'ship_from_address', 'consignee_address', 'bill_to_address', 'References'],
        showExport: true,
        additionalFeatures: ['search', 'progress']
    },
    'Online Transport': {
        columns: ['file_name', 'Invoice Number', 'Amount'],
        showExport: true,
        additionalFeatures: ['search', 'progress']
    },
    'toll group': {
        columns: ['file_name', 'document_type'],
        showExport: false,
        additionalFeatures: ['search', 'progress']
    },
    default: {
        columns: ['file_name', 'ship_from_address', 'consignee_address', 'References'],
        showExport: true,
        additionalFeatures: ['search', 'progress']
    }
};

// Column display names mapping
const COLUMN_LABELS = {
    file_name: 'File Name',
    ship_from_address: 'Shipping Address',
    consignee_address: 'Consignee Address',
    bill_to_address: 'Bill To Address',
    References: 'References',
    'Invoice Number': 'Invoice Number',
    Amount: 'Amount',
    document_type: 'Document Type'
};

const processResults = (results) => {
    return results.flatMap(result => {
        // For Online Transport format (with references array)
        if (result.return_all && Array.isArray(result.references)) {
            return result.references.map(invoice => ({
                file_name: result.file_name,
                'Invoice Number': String(invoice['Invoice Number']),
                'Amount': typeof invoice['Amount'] === 'number'
                    ? `$${invoice['Amount'].toFixed(2)}`
                    : invoice['Amount'].startsWith('$')
                        ? invoice['Amount']
                        : `$${invoice['Amount']}`
            }));
        }

        // For aidocx demo format (with addresses and References)
        return [{
            file_name: result.file_name,
            ship_from_address: result.ship_from_address || '-',
            consignee_address: result.consignee_address || '-',
            bill_to_address: result.bill_to_address || '-',
            References: result.References || {},
            weight: result.weight,
            gross_weight: result.gross_weight,
            document_type: result.references?.document_type || '-'
        }];
    });
};

function ViewResults() {
    const [results, setResults] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useAuth();
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [progress, setProgress] = useState({ processed: 0, total: 0 });
    const [isProcessing, setIsProcessing] = useState(false);

    const companyConfig = useMemo(() => {
        // console.log('User company:', user?.company);
        return COMPANY_CONFIGS[user?.company] || COMPANY_CONFIGS.default;
    }, [user?.company]);

    const fetchProgress = async () => {
        if (!companyConfig.additionalFeatures.includes('progress')) return;

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            if (!user?.company) {
                // console.log('No user or company information available');
                return;
            }

            const response = await axios.get(`/progress/${user.company}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const { processed, total } = response.data;
            setProgress({ processed, total });
            setIsProcessing(processed !== total);
        } catch (error) {
            console.error('Error fetching progress:', error);
            if (error.response?.status === 401) navigate('/login');
        }
    };

    useEffect(() => {
        const fetchResults = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    navigate('/login');
                    return;
                }

                const response = await axios.get('/results', {
                    headers: { Authorization: `Bearer ${token}` }
                });

                // Process the results before setting them
                const processedResults = processResults(response.data.data);
                setResults(processedResults);
            } catch (error) {
                console.error('Error fetching results:', error);
                if (error.response?.status === 401) navigate('/login');
            }
        };

        fetchResults();
    }, [navigate]);

    useEffect(() => {
        let intervalId = null;

        const startPolling = () => {
            if (!companyConfig.additionalFeatures.includes('progress')) return;

            fetchProgress();
            intervalId = setInterval(() => {
                fetchProgress();
            }, isProcessing ? 1000 : 5000);
        };

        if (location.pathname.startsWith('/results') && user?.company) {
            startPolling();
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [location.pathname, user, isProcessing]);

    useEffect(() => {
        const filtered = results.filter(result => {
            const searchLower = searchTerm.toLowerCase();

            // For Online Transport format
            if (result['Invoice Number']) {
                return result.file_name.toLowerCase().includes(searchLower) ||
                    result['Invoice Number'].toLowerCase().includes(searchLower);
            }

            // For aidocx demo format
            return result.file_name.toLowerCase().includes(searchLower) ||
                (result.References && Object.values(result.References)
                    .some(value => value.toLowerCase().includes(searchLower)));
        });
        setFilteredResults(filtered);
    }, [searchTerm, results]);

    const handleDetailsButtonClick = (fileName) => {
        navigate(`/details/${fileName}`);
    };

    const handleDownload = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            const response = await axios.get('/results/export', {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob',
            });

            const contentType = response.headers['content-type'];
            const fileExtension = contentType.includes('csv') ? 'csv' : 'xlsx';
            const blob = new Blob([response.data], { type: contentType });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `results.${fileExtension}`;
            link.click();
            window.URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Error downloading file:', error);
            if (error.response?.status === 401) navigate('/login');
        }
    };

    // Render cell content based on column type
    const renderCell = (result, column) => {
        if (column === 'References' && result.References) {
            return (
                <ul className="list-none space-y-1">
                    {Object.entries(result.References)
                        .filter(([key, value]) => key?.trim() && value?.trim())
                        .map(([key, value], index) => (
                            <li key={index} className="text-sm">
                                <span className="font-medium text-gray-700">{key}:</span>
                                <span className="ml-2 text-gray-600">{value}</span>
                            </li>
                        ))}
                </ul>
            );
        }

        return result[column] || '-';
    };

    return (
        <div className="w-full p-6">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-800">Document Results</h2>
                {companyConfig.showExport && (
                    <button
                        onClick={handleDownload}
                        className="inline-flex items-center gap-2 px-3 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded transition-colors"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                        Export
                    </button>
                )}
            </div>

            {companyConfig.additionalFeatures.includes('search') && (
                <div className="mb-4 relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input
                        type="search"
                        className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Search by file name..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            )}

            {companyConfig.additionalFeatures.includes('progress') && (
                <div className="mb-6">
                    <div className="flex justify-between text-sm text-gray-600 mb-1">
                        <span>Documents Processing Status</span>
                        <span>
                            {Math.min(progress.processed, progress.total)} / {progress.total}
                        </span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div
                            className="bg-green-700 h-2.5 rounded-full transition-all duration-500"
                            style={{ width: `${Math.min((progress.processed / progress.total) * 100, 100)}%` }}
                        ></div>
                    </div>
                </div>
            )}

            <div className="overflow-x-auto shadow-sm rounded-lg">
                <table className="min-w-full bg-white">
                    <thead>
                        <tr className="bg-gray-50 text-gray-600 text-sm border-b border-gray-300">
                            {companyConfig.columns.map(column => (
                                <th key={column} className="sticky top-0 px-4 py-3 text-left font-bold">
                                    {COLUMN_LABELS[column]}
                                </th>
                            ))}
                            <th className="sticky top-0 px-4 py-3 text-center font-bold">Details</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100">
                        {filteredResults.map((result, index) => (
                            <tr key={index} className="hover:bg-gray-50 text-sm">
                                {companyConfig.columns.map(column => (
                                    <td key={column} className="px-4 py-3 text-gray-900 border-b border-gray-300">
                                        {renderCell(result, column)}
                                    </td>
                                ))}
                                <td className="px-4 py-3 border-b border-gray-300">
                                    <div className="flex flex-col items-center justify-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="size-6 mb-1"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                                            />
                                        </svg>
                                        <button
                                            onClick={() => handleDetailsButtonClick(result.file_name)}
                                            className="font-medium text-blue-600 dark:text-blue-500 underline hover:no-underline focus:outline-none"
                                        >
                                            Details
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        {filteredResults.length === 0 && (
                            <tr>
                                <td colSpan={companyConfig.columns.length + 1} className="px-4 py-8 text-center text-gray-500">
                                    No results found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ViewResults;