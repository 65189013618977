import React from 'react';

const Header = ({ logo }) => {
  return (
    <header className="sticky top-4 inset-x-0 z-50 w-full">
      <nav className="relative max-w-[66rem] w-full bg-neutral-800 rounded-[28px] py-3 px-5 flex items-center justify-between mx-2 lg:mx-auto" aria-label="Global">
        <div className="flex items-center">
          <img className="flex-shrink-0 size-8 rounded-full border-[3px] border-neutral-800 w-20 h-20" src={logo} alt="Logo" />
        </div>

        <div className="flex items-center space-x-4">
          <a className="text-lg text-white hover:text-neutral-300 focus:outline-none focus:text-neutral-300" href="/">Home</a>
          <a className="text-lg text-white hover:text-neutral-300 focus:outline-none focus:text-neutral-300" href="/login">Login</a>
          <a className="inline-flex items-center py-1 px-2 bg-orange-400 font-medium text-lg text-neutral-800 rounded-full focus:outline-none" href="#contact">
            Book A Demo
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Header;